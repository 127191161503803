<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M8.00065 1.33301C11.6827 1.33301 14.6673 4.31767 14.6673 7.99967C14.6673 11.6817 11.6827 14.6663 8.00065 14.6663C4.31865 14.6663 1.33398 11.6817 1.33398 7.99967C1.33398 4.31767 4.31865 1.33301 8.00065 1.33301ZM8.00065 2.66634C6.58616 2.66634 5.22961 3.22824 4.22942 4.22844C3.22922 5.22863 2.66732 6.58519 2.66732 7.99967C2.66732 9.41416 3.22922 10.7707 4.22942 11.7709C5.22961 12.7711 6.58616 13.333 8.00065 13.333C9.41514 13.333 10.7717 12.7711 11.7719 11.7709C12.7721 10.7707 13.334 9.41416 13.334 7.99967C13.334 6.58519 12.7721 5.22863 11.7719 4.22844C10.7717 3.22824 9.41514 2.66634 8.00065 2.66634V2.66634ZM8.00065 4.66634C8.70238 4.66661 9.38612 4.88832 9.95447 5.2999C10.5228 5.71148 10.9468 6.29192 11.166 6.95854C11.3851 7.62516 11.3883 8.34394 11.1751 9.01249C10.9619 9.68104 10.5431 10.2652 9.9785 10.6819C9.41385 11.0985 8.73211 11.3263 8.03042 11.3329C7.32872 11.3394 6.64287 11.1243 6.07056 10.7183C5.49825 10.3122 5.06868 9.7359 4.84305 9.07144C4.61743 8.40698 4.60726 7.68826 4.81398 7.01767C4.96993 7.36615 5.24083 7.65049 5.58136 7.82312C5.92188 7.99574 6.31136 8.04618 6.68462 7.96598C7.05788 7.88578 7.39227 7.67982 7.63183 7.38256C7.8714 7.0853 8.0016 6.71478 8.00065 6.33301C8.00074 6.01199 7.90811 5.69777 7.73391 5.42812C7.55971 5.15848 7.31134 4.94488 7.01865 4.81301C7.33685 4.71547 7.66784 4.66603 8.00065 4.66634V4.66634Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PasswordUnlockIcon",
};
</script>
